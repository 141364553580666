<div class="navbar w-full pl-3 pr-3 mt-2">
  <div tabindex="0" class="navbar-start">
    <!-- <a routerLink="/">
      <img src="../../../assets/optimist-logo.png" alt="Optimist Logo"
    /></a> -->
    <a
      routerLink="/"
      class="btn btn-ghost normal-case text-sm md:text-base text-black rounded-full"
      >Optimist Dia</a
    >
  </div>

  <!-- Website Name -->
  <div tabindex="1" class="navbar-center max-md:hidden">
    <div class="flex flex-row justify-center align-middle items-center">
      <ng-container *ngIf="(isLoggedin$ | async) === true">
        <div class="flex flex-col font-medium text-sm uppercase rounded-full">
          <span *ngIf="user$ | async as user">{{ user.name }}</span>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Search -->
  <div tabindex="2" class="navbar-end">
    <!-- <div class="form-control">
        <input type="text" placeholder="Ara" class="input input-bordered" />
      </div> -->

    <ng-container *ngIf="(isLoggedin$ | async) === true">
      <button
        (click)="logout()"
        class="btn btn-link text-xs min-lg:text-base normal-case text-black hover:text-orange-600"
      >
        Çıkış
      </button>
      <div
        class="btn btn-ghost rounded-full text-center"
      >
        <app-avatar />
      </div>
    </ng-container>
    <!-- <div *ngIf="(isLoggedin$ | async) === undefined">
        <a
          routerLink="user"
          class="btn btn-ghost normal-case text-xl text-black"
          >Giriş</a
        >
      </div> -->
  </div>
</div>
