import { Injectable } from '@angular/core';
import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from "@angular/router";
@Injectable({
  providedIn: 'root'
})
export class RouteReuseService implements RouteReuseStrategy {

  private savedHandles = new Map<string, DetachedRouteHandle>();

  shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return before.routeConfig === curr.routeConfig;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.savedHandles.has(this.getRouteKey(route));
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data['saveComponent'];
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const key = this.getRouteKey(route);
    this.savedHandles.set(key, handle);
  }

  private getRouteKey(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.filter(u => u.url).map(u => u.url).join('/');
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.savedHandles.get(this.getRouteKey(route)) || null;
  }
}
