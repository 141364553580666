// server-status.initializer.ts
import { ServerStatusService } from '../services/server-status.service';

export function initializeServerStatus(
  serverStatusService: ServerStatusService,
) {
  return (): Promise<any> => {
    return serverStatusService.checkServerStatusOnce();
  };
}
