import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, of } from 'rxjs';
import {
  catchError,
  finalize,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/core/store/models/app.model';
import { selectUser } from 'src/app/core/store/selectors/user.selectors';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
})
export class AvatarComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public avatar: string = 'assets/optimist-logo.png';
  private destroy$ = new Subject<void>();

  constructor(
    private auth: AuthService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectUser)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((user: any) => {
          this.isLoading = true;
          return user ? this.setAvatar(user) : of({ url: this.avatar });
        }),
      )
      .subscribe({
        next: (data: any) => {
          this.avatar =
            data?.companyLogo?.url ||
            data?.avatar?.url ||
            data?.url ||
            this.avatar;
          this.isLoading = false;
        },
        error: (err) => console.error('Error setting avatar:', err),
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setAvatar(user: User) {
    const endpoint = this.getEndpoint(user);

    if (endpoint) {
      return this.auth.fetchApi(endpoint, { depth: 1 }).pipe(
        catchError((error) => {
          console.error('Error fetching avatar:', error);
          this.isLoading = false;
          return of({ url: this.avatar });
        }),
        finalize(() => (this.isLoading = false)),
      );
    } else {
      return of({ url: this.avatar });
    }
  }

  private getEndpoint(user: User): string | undefined {
    const id = this.getId(user.avatar, user.role === 'single-user');
    if (id) {
      return `/api/avatars/${id}`;
    }

    const companyId = this.getId(user.company);
    if (companyId) {
      return `/api/companies/${companyId}`;
    }

    return undefined;
  }

  private getId(
    property: string | { id: string } | undefined,
    isAvatar = false,
  ): string | undefined {
    if (typeof property === 'object') {
      return property?.id;
    } else if (isAvatar && typeof property === 'string') {
      return property;
    }

    return undefined;
  }
}
