import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import {
  selectForceChangePassword,
  selectLoggedIn,
} from '../store/selectors/user.selectors';
import { Observable, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.store.select(selectLoggedIn),
      this.store.select(selectForceChangePassword),
    ]).pipe(
      map(([loggedIn, forceChangePassword]) => {
        if (!loggedIn) {
          this.router.navigate(['/giris']);
          return false;
        }
        if (forceChangePassword) {
          return this.router.parseUrl('/parola-sifirlama');
        }
        return true;
      }),
    );
  }
}
