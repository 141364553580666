import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from '../reducers/user.reducer';

export const selectUserState = createFeatureSelector<State>('user');

export const selectUser = createSelector(
  selectUserState,
  (state: State) => state.user,
);

export const selectForceChangePassword = createSelector(selectUser, (user) =>
  user ? user.forceChangePassword : false,
);

export const selectLoggedIn = createSelector(
  selectUser,
  (user) => !!user && user.id !== undefined,
);

export const selectUserId = createSelector(selectUser, (user) =>
  user ? user.id : undefined,
);

export const selectTokenRefreshed = createSelector(
  selectUserState,
  (state: State) => !state.refreshingToken,
);
