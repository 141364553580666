<div
  class="flex flex-col justify-center align-items-center w-[100vw] min-h-screen h-full"
>
  <div
    id="container"
    class="rounded-[50px] bg-[#f8f1e7] flex flex-col auto-cols-max w-[95vw] sm:w-[90vw] xl:w-[85vw] xxl:w-[80vw] mt-[1vh] sm:mt-[1vh] md:mt-[2vh] lg:mt-[3vh] xl:mt-[4vh] xxl:mt-[6vh] mb-[1vh] sm:mb-[1vh] md:mb-[2vh] lg:mb-[3vh] xl:mb-[4vh] xxl:mb-[6vh] min-h-[80vh] transition-all transition-duration-1000"
  >
    <navbar class="pt-3" />
    <div class="divider pl-3 pr-3"></div>
    <router-outlet />
    <!-- <footer></footer> -->
  </div>
  <footer class="p-10">
    <span class="text-xs"
      >{{currentYear}} -
      <a href="https://iyifikirmedya.com/" target="_blank">İyi Fikir Medya</a>.
      Tüm hakları saklıdır.</span
    >
  </footer>
</div>
