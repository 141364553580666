import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';

import { catchError, map, take, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import {
  UserApiAction,
  UserLoggedInAction,
} from '../store/actions/user.action';
import { User } from '../store/models/app.model';

interface dbObject {
  url?: string;
}

export interface RefreshTokenResponse {
  exp: number;
  message: string;
  refreshedToken: string;
  user: User;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // // userObject keeps login status across the application
  // public userObject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  user$: Observable<User>;
  // public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
  //   false
  // );

  public loggedIn$: Observable<boolean> = this.store.select('loggedIn');

  public dbObject: dbObject = {
    url: environment.serverUrl,
  };

  // url: string = "https://dia.iyifikirmedya.com";
  // url: dbObject = "http://localhost:3000";

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<{ loggedIn: boolean; user: User }>,
  ) {
    this.user$ = this.store.select('user');
  }

  // userObject as observable - currently not in use, for educational purposes only
  // userObservable = this.userObject.asObservable();

  public constructUrl(api: string): string {
    return `${this.dbObject.url}${api}`;
  }

  /** Gets data from the specified API.
   * @param {string} url - The API endpoint to get data from.
   * @param {Object} [query] - The query parameters.
   * @param {number} [query.depth] - The depth of the query.
   * @param {number} [query.limit] - The limit of the query.
   * @param {Object} [query.where] - The where clause of the query.
   * @param {number} [query.page] - The page of the query.
   * @param {boolean} [query.pagination] - Whether to paginate the query.
   * @param {string} [query.sort] - The sort order of the query.
   * @returns {Observable<any>} An Observable that emits the server's response.
   * @memberof AuthService
   * @example
   * // Get all the ebooks
   * this.authService.fetchApi('/ebooks/').subscribe((ebooks) => {
   *  console.log(ebooks);
   * });
   * @example
   * // Get the first 10 ebooks
   * this.authService.fetchApi('/ebooks/', { limit: 10 }).subscribe((ebooks) => {
   * console.log(ebooks);
   * });
   * @example
   * // Get the first 10 ebooks with a depth of 2
   * this.authService.fetchApi('/ebooks/', { limit: 10, depth: 2 }).subscribe((ebooks) => {
   * console.log(ebooks);
   * });
   * @example
   * // Get the first 10 ebooks with a depth of 2 and a where clause
   * this.authService.fetchApi('/ebooks/', { limit: 10, depth: 2, where: { ebookTitle: 'Harry Potter' } }).subscribe((ebooks) => {
   * console.log(ebooks);
   * });
   */
  public fetchApi(
    url: string,
    query: {
      depth?: number;
      limit?: number;
      where?: any;
      page?: number;
      pagination?: boolean;
      sort?: string;
      [key: string]: any;
    } = {},
  ): Observable<any> {
    let params = new HttpParams();

    for (const key in query) {
      if (query[key] != null) {
        // This will check for both `null` and `undefined`
        params = params.set(
          key,
          typeof query[key] === 'object'
            ? JSON.stringify(query[key])
            : query[key],
        );
      }
    }

    const options = { params, withCredentials: true };

    return this.http.get(this.constructUrl(url), options).pipe(
      map((res: any) => res.docs || res),
      catchError(this.handleError),
    );
  }

  /**
   * Sets data on the specified API.
   *
   * @param {string} api - The API endpoint to set data on.
   * @param {string} id - The ID of the item to set data on.
   * @param {any} [body] - The data to set.
   * @returns {Observable<any>} An Observable that emits the server's response.
   */
  public setApi(api: string, id: string, body: any = {}): Observable<any> {
    return this.http.patch(this.constructUrl(api + id), body, {
      withCredentials: true,
    });
  }

  /**
   * Logs in the user.
   *
   * @param {Object} formData - The form data.
   * @param {string} formData.email - The username.
   * @param {string} formData.password - The password.
   * @returns {Observable<any>} An Observable that emits the server's response.
   */
  public login(formData: { email: string; password: string }): Observable<any> {
    return this.http
      .post<any>(`${this.dbObject.url}/api/users/login`, formData, {
        withCredentials: true,
      })
      .pipe(tap((response) => console.log('Server response:', response)));
  }

  /**
   * Logs out the user.
   *
   * @returns {Observable<any>} An Observable that emits the server's response.
   */
  public logout() {
    return this.http
      .post(
        `${this.dbObject.url}/api/users/logout`,
        {},
        { withCredentials: true },
      )
      .pipe(
        take(1),
        tap((val: any) => {
          console.log('Logout response: ', val);

          // Dispatch actions to update the state
          this.store.dispatch(
            UserLoggedInAction.userLoggedIn({ loggedIn: false }),
          );
          this.store.dispatch(UserApiAction.logoutComplete({ user: null }));

          // Navigate to login page after logout
          console.log('Navigating to login page');
          this.router.navigate(['/giris']);
        }),
        catchError((error: any) => {
          console.error('Error during logout: ', error);
          return throwError(() => error);
        }),
      );
  }

  public sendPasswordResetEmail(formData: { email: string }) {
    return this.http
      .post<any>(`${this.dbObject.url}/api/users/forgot-password`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        take(1),
        tap((response) => {
          console.log('Send password reset email response: ', response.message);
        }),
        catchError(this.handleError),
      );
  }

  public changePassword(formData: { token: string; password: string }) {
    return this.http
      .post<any>(`${this.dbObject.url}/api/users/reset-password`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        take(1),
        tap((response) => {
          console.log('Change password response: ', response.message);
        }),
      );
  }

  /**
   * Checks the API access.
   *
   * @returns {Observable<any>} An Observable that emits the server's response.
   */
  public apiAccess() {
    return this.http.get(`${this.dbObject.url}/api/access`, {
      withCredentials: true,
    });
  }

  /**
   * Checks if the user is logged in.
   * If fetchLoginStatus is true, it fetches the login status from the server.
   * If userObject is set and the user is not null, it dispatches a UserLoggedInAction with loggedIn set to true.
   * If userObject is not set, it fetches the login status from the server and updates userObject and loggedIn based on the response.
   * @param args An optional object that can contain a fetchLoginStatus property.
   * @returns An Observable that emits the login status of the user.
   */
  public isLoggedIn(args?: { fetchLoginStatus: boolean }): Observable<boolean> {
    // If fetchLoginStatus is true, fetch login status from the server
    if (args && args.fetchLoginStatus) {
      return this.fetchLoginStatus();
    }

    // If fetchLoginStatus is not true, return the 'loggedIn' state from the store
    return this.store.select('loggedIn');
  }

  /**
   * Fetches the login status of the user from the server.
   * @returns An Observable that emits the login status of the user.
   */
  private fetchLoginStatus() {
    return this.fetchApi('/api/users/me').pipe(
      // The map operator is used to process the response from the server.
      // The handleLoginStatus method is called with the server response as an argument.
      map((res: any) => this.handleLoginStatus(res)),
    );
  }

  /**
   * Handles the login status response from the server.
   * If the user is logged in, it updates userObject and dispatches a UserLoggedInAction with loggedIn set to true.
   * If the user is not logged in, it updates userObject and dispatches a UserLoggedInAction with loggedIn set to false.
   * @param res The response from the server.
   * @returns A boolean indicating whether the user is logged in.
   */
  private handleLoginStatus(res: any): boolean {
    if (res.user) {
      this.store.dispatch(UserApiAction.retrieveUserObject({ user: res }));
      this.store.dispatch(UserLoggedInAction.userLoggedIn({ loggedIn: true }));
      return true;
    } else {
      this.store.dispatch(UserApiAction.logoutComplete({ user: null }));
      this.store.dispatch(UserLoggedInAction.userLoggedIn({ loggedIn: false }));
      return false;
    }
  }

  public refreshToken(): Observable<RefreshTokenResponse> {
    return this.http
      .post<RefreshTokenResponse>(
        `${this.dbObject.url}/api/users/refresh-token`,
        {},
        { withCredentials: true },
      )
      .pipe(
        tap((response: RefreshTokenResponse) => {
          // Assuming the user object is in the response
          this.store.dispatch(
            UserApiAction.retrieveUserObject({ user: response.user }),
          );
        }),
      );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      switch (error.status) {
        case 403:
          errorMessage = 'You are not allowed to perform this action.';
          break;
        case 404:
          errorMessage = 'The requested resource was not found.';
          break;
        case 500:
          errorMessage =
            'The server encountered an error. Please try again later.';
          break;
        default:
          errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
      }
    }

    // Log the error message to the console
    console.error(errorMessage);

    // Return an observable with a user-facing error message
    return throwError(() => ({
      status: error.status,
      error: { errors: [{ message: errorMessage }] },
    }));
  }
}
