import { state } from '@angular/animations';
import { createReducer, on } from '@ngrx/store';
import { ContentApiActions } from '../../store/actions/ebooks.actions';
import { UserApiAction } from '../../store/actions/user.action';

// export const initialState: ReadonlyArray<string> = [];

const initialState: {} = {};

export const collectionReducer = createReducer(
  initialState,
  on(ContentApiActions.retrieveArticles, (state, { articles }) => {
    return { ...state, articles };
  }),
  on(ContentApiActions.retrieveEbooks, (state, { ebooks }) => {
    return {
      ...state,
      ebooks,
    };
  }),
  on(ContentApiActions.retrieveShortbooks, (state, { shortbooks }) => {
    return {
      ...state,
      shortbooks,
    };
  }),
  on(ContentApiActions.retrieveAudiobooks, (state, { audiobooks }) => {
    return {
      ...state,
      audiobooks,
    };
  }),
  on(
    ContentApiActions.retrieveShortAudiobooks,
    (state, { shortaudiobooks }) => {
      return {
        ...state,
        shortaudiobooks,
      };
    },
  ),
  on(ContentApiActions.retrieveGraphicBooks, (state, { graphicbooks }) => {
    return {
      ...state,
      graphicbooks,
    };
  }),
  on(ContentApiActions.retrieveInfographics, (state, { infographics }) => {
    return {
      ...state,
      infographics,
    };
  }),
  on(ContentApiActions.retrieveAudioArticles, (state, { audioarticles }) => {
    return {
      ...state,
      audioarticles,
    };
  }),
  on(
    ContentApiActions.retrieveDevelopmentBulletins,
    (state, { developmentbulletins }) => {
      return {
        ...state,
        developmentbulletins,
      };
    },
  ),
);
