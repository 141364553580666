import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { interval, Subscription, of, BehaviorSubject } from 'rxjs';
import {
  tap,
  catchError,
  concatMap,
  timeout,
  delay,
  filter,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { selectUser } from '../store/selectors/user.selectors';
import { UserApiAction } from '../store/actions/user.action';

@Injectable({
  providedIn: 'root',
})
export class ServerStatusService {
  private checkIntervalSubscription: Subscription | null = null;
  private readonly serverUrl = environment.serverUrl;

  // Add a BehaviorSubject to keep track of the server's online/offline status
  public serverStatus = new BehaviorSubject<boolean | null>(null);
  isOffline = this.serverStatus.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
  ) {
    // Subscribe to navigation events
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart), // Only emit for NavigationStart events
      )
      .subscribe(() => {
        // Store the current route
        this.storeCurrentRoute();
      });
  }

  checkServerStatusOnce(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.serverUrl}/api/users/me`, { withCredentials: true })
        .pipe(
          tap((response: any) => {
            this.serverStatus.next(false); // Server is online, update serverStatus
            if (response.user) {
              this.storeUserInStore(response.user); // Store the user object in the store
            }
            this.redirectToLastRoute(); // Redirect to the last route
            resolve();
          }),
          catchError(() => {
            this.serverStatus.next(true); // Server is offline, update serverStatus
            this.storeCurrentRoute(); // Store the current route
            resolve(); // Resolve the promise even if the server is offline
            return of(null);
          }),
        )
        .subscribe();
    });
  }

  startCheckingServerStatusPeriodically(): void {
    this.checkIntervalSubscription = interval(5000) // Check every 5 seconds
      .pipe(
        concatMap(() =>
          this.http
            .get(`${this.serverUrl}/api/users/me`, { withCredentials: true })
            .pipe(
              timeout(4000), // Add timeout
              tap((response: any) => {
                console.log('Server is online'); // Add logging
                this.serverStatus.next(false); // Server is online, update serverStatus
                if (response.user) {
                  this.storeUserInStore(response.user); // Store the user object in the store
                }
                this.storeCurrentRoute(); // Store the current route
                this.redirectToLastRoute(); // Redirect to the last route
                this.stopCheckingServerStatus(); // Stop checking server status
              }),
              catchError((error) => {
                console.log('HTTP request failed, error:', error); // Add logging
                this.serverStatus.next(true); // Server is offline, update serverStatus
                return of(null); // Return a null value to prevent the error from propagating
              }),
            ),
        ),
      )
      .subscribe();
  }

  private storeUserInStore(user: any): void {
    this.store.dispatch(UserApiAction.retrieveUserObject({ user }));
  }

  stopCheckingServerStatus(): void {
    if (this.checkIntervalSubscription) {
      this.checkIntervalSubscription.unsubscribe();
      this.checkIntervalSubscription = null;
    }
  }

  private storeCurrentRoute(): void {
    // Only store the current route if the server is offline
    if (this.serverStatus.value) {
      // Store the current route in the local storage
      localStorage.setItem('lastRoute', this.router.url);
    }
  }

  redirectToLastRoute(): void {
    // Get the last route from the local storage
    const lastRoute = localStorage.getItem('lastRoute');

    if (lastRoute) {
      // Navigate to the last route
      this.router.navigateByUrl(lastRoute);

      // Remove the last route from the local storage
      localStorage.removeItem('lastRoute');
    } else {
      // Navigate to the home page
      this.router.navigateByUrl('/');
    }
  }
}
