<label tabindex="0" class="btn btn-ghost btn-circle avatar bg-white">
  <div class="w-20 rounded-full">
    <!-- <img src="https://placeimg.com/80/80/people" /> -->
    <img *ngIf="!isLoading; else loading" [src]="avatar" alt="Avatar" class="avatar-img" />
    <ng-template #loading>
      <div class="avatar-placeholder">
        <span class="icon icon-32 icon-loading"></span>
      </div>
    </ng-template>
  </div>
</label>

