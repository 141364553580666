import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { loginGuard } from './core/guards/login.guard';
import { canAccessLoginGuard } from './core/guards/can-access-login.guard';

const routes: Routes = [
  {
    path: 'icerik',
    loadChildren: () =>
      import('./pages/content/content.module').then((m) => m.ContentModule),
  },
  {
    path: 'giris',
    loadChildren: () =>
      import('./pages/user/user.module').then((m) => m.UserModule),
    canActivate: [canAccessLoginGuard],
  },
  {
    path: 'parola-sifirlama',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule,
      ),
    canActivate: [loginGuard],
  },
  {
    path: 'parola-sifirlama/:token',
    loadChildren: () =>
      import('./pages/new-password/new-password.module').then(
        (m) => m.NewPasswordModule,
      ),
  },
  {
    path: 'parolami-unuttum',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule,
      ),
  },
  {
    path: 'baglanti-yok',
    loadChildren: () =>
      import('./pages/server-offline/server-offline.module').then(
        (m) => m.ServerOfflineModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: {
      saveComponent: false,
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
