import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, retryWhen, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ServerStatusService } from '../services/server-status.service';

@Injectable()
export class ServerStatusInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private http: HttpClient,
    private serverStatusService: ServerStatusService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // If the request is to the /api/access endpoint, bypass the interceptor
    if (request.url.endsWith('/api/access')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      retry({ count: 1, delay: 1000 }), // Retry 5 times with a delay of 1 second between each retry
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          this.serverStatusService.serverStatus.next(true);
          return this.checkServerAccess();
        }
        return throwError(() => error);
      }),
    );
  }

  private checkServerAccess(): Observable<never> {
    return this.http.get(`${environment.serverUrl}/api/access`).pipe(
      switchMap(() => {
        // If the access endpoint is reachable, rethrow the original error
        return throwError(() => new Error('Access endpoint is reachable'));
      }),
      catchError(() => {
        // If the access endpoint is also unreachable, redirect to the offline page and throw an error
        this.router.navigate(['/baglanti-yok']);
        return throwError(() => new Error('Server is offline'));
      }),
    );
  }
}
