import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';
import { selectLoggedIn } from '../store/selectors/user.selectors';

export const loginGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectLoggedIn).pipe(
    tap((loggedIn) => console.log('loggedIn:', loggedIn)),
    take(1),
    map((loggedIn) => {
      if (!loggedIn) {
        return router.parseUrl('/giris');
      }
      return true;
    }),
  );
};