import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { selectLoggedIn } from 'src/app/core/store/selectors/user.selectors';
import { ServerStatusService } from '../services/server-status.service';
import { combineLatest } from 'rxjs';

export const canAccessLoginGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);
  const serverStatusService = inject(ServerStatusService);

  return combineLatest([
    store.select(selectLoggedIn),
    serverStatusService.isOffline,
  ]).pipe(
    tap(([loggedIn, isOffline]) =>
      console.log('Logged In:', loggedIn, 'Is Offline:', isOffline),
    ),
    map(([loggedIn, isOffline]) => {
      if (loggedIn) {
        router.navigate(['/']);
        return false;
      } else if (isOffline) {
        router.navigate(['/baglanti-yok']);
        return false;
      }
      return true;
    }),
  );
};