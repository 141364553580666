// auth.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { UserApiAction } from '../../store/actions/user.action';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiAction.login),
      switchMap((action) =>
        this.authService
          .login({ email: action.username, password: action.password })
          .pipe(
            tap((response) => console.log('Server response:', response)),
            map((response) => {
              console.log('User data:', response.user);
              console.log('Dispatching LoginSuccess action');
              // Navigate to the home screen
              this.router.navigate(['/']);
              return UserApiAction.loginSuccess({ user: response.user });
            }),
            catchError(this.handleError(UserApiAction.loginFailure)),
          ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiAction.logout),
      switchMap(() =>
        this.authService.logout().pipe(
          map(() => UserApiAction.logoutComplete({ user: null })),
          catchError(this.handleError(UserApiAction.logoutFailure)),
        ),
      ),
    ),
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserApiAction.refreshToken),
      mergeMap(() =>
        this.authService.refreshToken().pipe(
          map(() => UserApiAction.refreshTokenSuccess()),
          catchError(this.handleError(UserApiAction.refreshTokenFailure)),
        ),
      ),
    ),
  );

  private handleError(actionCreator: (props: { errorMessage: string }) => any) {
    return (error: any) => of(actionCreator({ errorMessage: error.message }));
  }
}
