import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import { ContentApiActions } from '../store/actions/ebooks.actions';
import { take, tap } from 'rxjs/operators';
import { State } from '../store/models/state.model';
import { User } from '../store/models/app.model';

@Injectable({
  providedIn: 'root',
})
export class ContentFetcherService {
  constructor(
    private auth: AuthService,
    private store: Store<State>,
  ) {}

  public fetchContent() {
    return this.auth.fetchApi('/api/users/me').pipe(
      tap((user: User | null) => {
        if (user !== null) {
          this.fetchContentFromApi(
            '/api/ebooks',
            ContentApiActions.retrieveEbooks,
          );
          this.fetchContentFromApi(
            '/api/articles',
            ContentApiActions.retrieveArticles,
          );
          this.fetchContentFromApi(
            '/api/shortbooks',
            ContentApiActions.retrieveShortbooks,
          );
          this.fetchContentFromApi(
            '/api/audiobooks',
            ContentApiActions.retrieveAudiobooks,
          );
          this.fetchContentFromApi(
            '/api/shortaudiobooks',
            ContentApiActions.retrieveShortAudiobooks,
          );
          this.fetchContentFromApi(
            '/api/graphicbooks',
            ContentApiActions.retrieveGraphicBooks,
          );
          this.fetchContentFromApi(
            '/api/infographics',
            ContentApiActions.retrieveInfographics,
          );
          this.fetchContentFromApi(
            '/api/audioarticles',
            ContentApiActions.retrieveAudioArticles,
          );
          this.fetchContentFromApi(
            '/api/developmentbulletins',
            ContentApiActions.retrieveDevelopmentBulletins,
          );
        }
      }),
      take(1),
    );
  }

  public listContentInStore() {
    return this.store.select((state) => state.collection);
  }

  private fetchContentFromApi(endpoint: string, action: any) {
    this.auth
      .fetchApi(endpoint, { limit: 500, depth: 0 })
      .pipe(take(1))
      .subscribe((content) => {
        this.store.dispatch(action({ content }));
      });
  }
}