import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouteReuseStrategy, Router } from '@angular/router';
import { RouteReuseService } from './core/services/route-reuse.service';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './core/store/effects/auth.effects';
import { accessReducer, userReducer } from './core/store/reducers/user.reducer';
import { collectionReducer } from './core/store/reducers/collection.reducer';
import { AvatarComponent } from './components/avatar/avatar.component';
import { NavbarMenuItemComponent } from './components/navbar-menu-item/navbar-menu-item.component';
import { ContentFetcherService } from './core/services/content-fetcher.service';
import { AuthService } from './core/services/auth.service';
import { UnderShelfComponent } from './components/under-shelf/under-shelf.component';
import { ButtonModule } from 'primeng/button';
import { GenerateContentLinkPipe } from './utilities/pipes/generate-content-link.pipe';
import appInitializer from './core/initializers/app.initializer';

import * as Sentry from '@sentry/angular-ivy';
import { ServerStatusService } from './core/services/server-status.service';
import { initializeServerStatus } from './core/initializers/server-status.initializer';
import { ServerStatusInterceptor } from './core/interceptors/server-status.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    AvatarComponent,
    NavbarMenuItemComponent,
    UnderShelfComponent,
    GenerateContentLinkPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot({
      user: userReducer,
      collection: collectionReducer,
      access: accessReducer,
    }),
    EffectsModule.forRoot([AuthEffects]),
    ButtonModule, // PrimeNG
  ],
  providers: [
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeServerStatus,
      deps: [ServerStatusService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AuthService, Store],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    ContentFetcherService,
    AuthService,
    {
      provide: RouteReuseStrategy,
      useClass: RouteReuseService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerStatusInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
