import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Store } from '@ngrx/store';
import { User } from 'src/app/core/store/models/app.model';
import {
  selectLoggedIn,
  selectUser,
} from 'src/app/core/store/selectors/user.selectors';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  settings: any = [];
  style: string = 'hidden';

  isLoggedin$ = this.store.select(selectLoggedIn);
  user$ = this.store.select(selectUser);

  constructor(
    private auth: AuthService,
    private store: Store<{ loggedIn: boolean; user: User }>,
  ) {}

  ngOnInit(): void {}

  logout(): void {
    this.auth.logout().subscribe({
      next: () => {
        console.log('Logout successful');
      },
      error: (error) => {
        console.error('Error during logout: ', error);
      },
    });
  }
}
