import { createActionGroup, props } from '@ngrx/store';
import {
  Article,
  Audioarticle,
  Audiobook,
  Developmentbulletin,
  Ebook,
  Graphicbook,
  Infographic,
  Shortaudiobook,
  Shortbook,
} from '../models/app.model';

// export const EbooksActions = createActionGroup({
//   source: 'Ebooks',
//   events: {
//     'Get Ebook': props<{ ebookId: string }>(),
//     'Update Ebook': props<{ ebookId: string }>(),
//   },
// });

export const ContentApiActions = createActionGroup({
  source: 'Content API',
  events: {
    'Retrieve Ebooks': props<{ ebooks: Array<Ebook> }>(),
    'Retrieve Articles': props<{ articles: Array<Article> }>(),
    'Retrieve Audio Articles': props<{ audioarticles: Array<Audioarticle> }>(),
    'Retrieve Shortbooks': props<{ shortbooks: Array<Shortbook> }>(),
    'Retrieve Audiobooks': props<{ audiobooks: Array<Audiobook> }>(),
    'Retrieve Short Audiobooks': props<{
      shortaudiobooks: Array<Shortaudiobook>;
    }>(),
    'Retrieve Graphic Books': props<{ graphicbooks: Array<Graphicbook> }>(),
    'Retrieve Infographics': props<{ infographics: Array<Infographic> }>(),
    'Retrieve Development Bulletins': props<{
      developmentbulletins: Array<Developmentbulletin>;
    }>(),
  },
});
