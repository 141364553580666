import { createReducer, on } from '@ngrx/store';

import { User } from '../models/app.model';
import {
  UserApiAction,
  UserLoggedInAction,
} from '../../store/actions/user.action';

export interface State {
  user: User | null;
  error: string | null;
  loggedIn: boolean;
  refreshingToken: boolean; // Add this line
}

export const initialState: State = {
  user: null,
  error: null,
  loggedIn: false,
  refreshingToken: false, // Add this line
};

export const userReducer = createReducer(
  initialState,
  on(UserApiAction.retrieveUserObject, (state, { user }) => ({
    ...state,
    user,
  })),
  on(UserApiAction.loginSuccess, (state, { user }) => ({
    ...state,
    user,
    loggedIn: true,
  })),
  on(UserLoggedInAction.userLoggedIn, (state, { loggedIn }) => ({
    ...state,
    loggedIn,
  })),
  on(UserApiAction.refreshToken, (state) => ({
    ...state,
    refreshingToken: true,
  })),
  on(UserApiAction.refreshTokenSuccess, (state) => ({
    ...state,
    refreshingToken: false,
  })),
  on(UserApiAction.loginFailure, (state, { errorMessage }) => ({
    ...state,
    error: errorMessage,
  })),
  on(UserApiAction.logout, (state) => ({
    ...state,
    user: null,
    loggedIn: false,
  })),
  on(UserApiAction.logoutComplete, (state) => ({
    ...state,
    user: null,
    loggedIn: false,
  })),
  on(UserApiAction.logoutFailure, (state, { errorMessage }) => ({
    ...state,
    error: errorMessage,
  })),
);

export const accessReducer = createReducer(
  {},
  on(UserApiAction.retrieveUserAccess, (_state, { access }) => access)
);