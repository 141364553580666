import { Store } from '@ngrx/store';
import { AuthService } from '../services/auth.service';
import { UserApiAction } from '../store/actions/user.action';

export default function appInitializer(
  authService: AuthService,
  store: Store,
): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      // Check if the user is logged in
      authService.fetchApi('/api/users/me').subscribe({
        next: (response) => {
          // If the request is successful and the user object is not null, update the state and refresh the token
          if (response.user) {
            store.dispatch(
              UserApiAction.retrieveUserObject({ user: response.user }),
            );
            // Dispatch loginSuccess action
            store.dispatch(UserApiAction.loginSuccess({ user: response.user }));

            authService.refreshToken().subscribe({
              next: () => resolve(true),
              error: (err) => {
                if (err.status === 403) {
                  // Handle the 403 error
                  // For example, you can log the user out
                  authService.logout();
                }
                reject(err);
              },
            });
          } else {
            // If the user object is null, resolve the promise without refreshing the token
            resolve(true);
          }
        },
        error: () => {
          // If the request fails, resolve the promise without refreshing the token
          resolve(true);
        },
      });
    });
  };
}
