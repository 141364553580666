import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Access } from '../models/access.model';
import { User } from '../models/app.model';

export const UserAction = createActionGroup({
  source: 'User',
  events: {
    'Get User': props<{ userId: string }>(),
    // 'Update User': props<{ userId: string }>(),
  },
});

export const UserApiAction = createActionGroup({
  source: 'User API',
  events: {
    'Retrieve User Object': props<{ user: null | User }>(),
    'Retrieve User Access': props<{ access: Access }>(),
    Login: props<{ username: string; password: string }>(),
    Logout: emptyProps(),
    'Login Success': props<{ user: User }>(),
    'Login Failure': props<{ errorMessage: string }>(),
    'Logout Complete': props<{ user: null }>(),
    'Logout Failure': props<{ errorMessage: string }>(),
    'Refresh Token': emptyProps(),
    'Refresh Token Success': emptyProps(),
    'Refresh Token Failure': props<{ errorMessage: string }>(),
  },
});

export const UserLoggedInAction = createActionGroup({
  source: 'User Logged In',
  events: {
    'User Logged In': props<{ loggedIn: any }>(),
  },
});
